<template>
  <div class="home">
    <v-container class="py-12">
      <v-row>
        <v-col class="text-center">
          <span class="d-block">Crea espacios únicos con nuestros productos</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span class="d-block">Encuentra el contraste perfecto, la textura ideal...</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <v-icon>mdi-arrow-down</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row>
        <template v-for="(prod, p) in prods">
          <v-col cols="12" sm="4" :key="'prod-'+p" class="px-0 py-0">
            <router-link :to="'/productos/'+prod.url">
              <v-img :src="'https://www.contractilecms.actstudio.xyz/'+prod.cover.path" aspect-ratio="1" position="center center" class="justify-center align-center">
                <div class="d-flex align-center justify-center background-covers">
                  <span class="title-cover">{{prod.name}}</span>
                </div>
              </v-img>
            </router-link>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      prods: []
    }
  },
  beforeMount: function () {

    var aux = this;
    fetch('https://www.contractilecms.actstudio.xyz/api/collections/get/Prods', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            // filter: {published:true},
            // fields: {fieldA: 1, fieldB: 1},
            // limit: 10,
            // skip: 5,
            // sort: {_created:-1},
            // populate: 1, // resolve linked collection items

            // lang: 'de' // return normalized language fields (fieldA_de => fieldA)
        })
    })
    .then(res=>res.json())
    .then(res => aux.prods = res.entries);
  }
}
</script>
