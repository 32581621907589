<template>
    <div class="producto">
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="6" class="pa-0">
                    <v-carousel height="auto" :show-arrows="true" hide-delimiters>
                        <v-carousel-item v-for="(image,i) in product.looks" :key="'slide-'+i">
                            <v-img :src="'https://www.contractilecms.actstudio.xyz/'+image.path"></v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col cols="12" md="6" class="text-left pa-md-12">
                    <span class="d-block gotham-light product-collection uppercase mb-3">{{product.colection_name}}</span>
                    <span class="d-inline-block gotham-light product-name px-3 mb-3">{{product.name}}</span>

                    <span class="d-block mb-3">{{product.type}}</span>

                    <span class="d-block gotham-bold">Tamaños:</span>

                    <span class="d-block mb-3">{{product.sizes}}</span>

                    <span class="d-block gotham-bold">Colores:</span>
                    <div class="mb-3">
                        <div style="width: 15px; height: 15px; border-radius: 50%;" :style="'background-color: '+product.color1.color" class="d-inline-block"></div>

                        <span class="d-inline-block ml-2 mr-3">{{product.color1.name}}</span>

                        <div style="width: 15px; height: 15px; border-radius: 50%;" :style="'background-color: '+product.color2.color" class="d-inline-block"></div>

                        <span class="d-inline-block ml-2 mr-3">{{product.color2.name}}</span>

                        <div style="width: 15px; height: 15px; border-radius: 50%;" :style="'background-color: '+product.color3.color" class="d-inline-block"></div>

                        <span class="d-inline-block ml-2 mr-3">{{product.color3.name}}</span>
                    </div>

                    <span class="d-block gotham-bold">Área de uso:</span>
                    <span class="d-block mb-3">{{product.areas}}</span>

                    <v-btn :href="'https://contractilecms.actstudio.xyz/'+product.file" target="_blank" outlined tile><span class="gotham-light not-uppercase">Descargar PDF</span></v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="mb-12">
            <v-row class="mt-md-12">
                <v-col cols="12">
                    <span class="gotham-light title-in-line uppercase d-block mb-2">VARIEDADES GRÁFICAS</span>
                    <hr>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6">
                    <v-row>
                        <v-col class="py-0">
                            <span class="gotham-light product-color-title">{{product.color1.name}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <template v-if="!(product.color1_prod1.size == '' || product.color1_prod1.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color1_prod1.image.path" :class="product.color1_prod1.class" class="d-block">

                                    {{product.color1_prod1.size}}
                                </div>
                            </template>
                            <template v-if="!(product.color1_prod2.size == '' || product.color1_prod2.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color1_prod2.image.path" :class="product.color1_prod2.class" class="d-block">

                                    {{product.color1_prod2.size}}
                                </div>
                            </template>
                            <template v-if="!(product.color1_prod3.size == '' || product.color1_prod3.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color1_prod3.image.path" :class="product.color1_prod3.class" class="d-block">

                                    {{product.color1_prod3.size}}
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </v-col>


                <v-col cols="12" sm="6">
                    <v-row>
                        <v-col class="py-0">
                            <span class="gotham-light product-color-title">{{product.color2.name}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <template v-if="!(product.color2_prod1.size == '' || product.color2_prod1.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color2_prod1.image.path" :class="product.color2_prod1.class" class="d-block">

                                    {{product.color2_prod1.size}}
                                </div>
                            </template>
                            <template v-if="!(product.color2_prod2.size == '' || product.color2_prod2.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color2_prod2.image.path" :class="product.color2_prod2.class" class="d-block">

                                    {{product.color2_prod2.size}}
                                </div>
                            </template>
                            <template v-if="!(product.color2_prod3.size == '' || product.color2_prod3.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color2_prod3.image.path" :class="product.color2_prod3.class" class="d-block">

                                    {{product.color2_prod3.size}}
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </v-col>


                <v-col cols="12" sm="6">
                    <v-row>
                        <v-col class="py-0">
                            <span class="gotham-light product-color-title">{{product.color3.name}}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <template v-if="!(product.color3_prod1.size == '' || product.color3_prod1.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color3_prod1.image.path" :class="product.color3_prod1.class" class="d-block">

                                    {{product.color3_prod1.size}}
                                </div>
                            </template>
                            <template v-if="!(product.color3_prod2.size == '' || product.color3_prod2.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color3_prod2.image.path" :class="product.color3_prod2.class" class="d-block">

                                    {{product.color3_prod2.size}}
                                </div>
                            </template>
                            <template v-if="!(product.color3_prod3.size == '' || product.color3_prod3.size == null)">
                                <div class="d-inline-block mr-3">
                                    <img :src="'https://contractilecms.actstudio.xyz/'+product.color3_prod3.image.path" :class="product.color3_prod3.class" class="d-block">

                                    {{product.color3_prod3.size}}
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data () {
        return {
            product: {

            }
        }
    },
    beforeMount: function () {
        fetch('https://www.contractilecms.actstudio.xyz/api/collections/get/Prods', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {url:this.$route.params.url},
                // fields: {fieldA: 1, fieldB: 1},
                // limit: 10,
                // skip: 5,
                // sort: {_created:-1},
                // populate: 1, // resolve linked collection items

                // lang: 'de' // return normalized language fields (fieldA_de => fieldA)
            })
        })
        .then(res=>res.json())
        .then(res => this.product = res.entries[0]); 
    }
}
</script>